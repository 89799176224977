/*

 Custom colors for Embrart organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

$organization_primary: #0c327b;
$organization_primary_focus: #184190;
$organization_primary_disabled: #0b3179b0;

$organization_program_default: #0c327b;

$organization_secondary: #184190;
$organization_secondary_focus: #0c327b;
$organization_secondary_transparent: #184190b3;
$organization_secundary_border: #6c9eff;

$organization_secundary_link: #ffffff;
$organization_secundary_link_hover: #cce7ff;

$organization_secundary_button: #6c9eff;
$organization_secundary_button_focus: #0c327b;
