/*

 Custom styles for Embrart organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

.header {
    align-items: center;
    &--logo {
        max-height: 65px;
    }
}
.login--logo {
    max-width: 70px !important;
}
.login--form-logo {
    padding: 20px !important;
    &-image {
        width: 115px !important;
    }
}
.container__header-provider .header-provider--logo {
    max-width: 81px !important;
}